import React, { useEffect } from "react";
import TGIcon from "../../../../../../shared/tg-icon";
import styles from "./error.module.scss";
import TGButtonVariants from "../../../../../../shared/tg-button-variants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { resetEreceiptDataRequest } from "../../../../slice/eReceiptSlice";
import { RootState } from "../../../../slice/RootReducer";
import TGButton from "../../../../../../shared/tg-button";

export const ErrorPage = () => {
  const navigate = useNavigate();
  const { error } = useSelector(
    (state: RootState) => state?.osciReducer?.eReceipt
  );
  const isLimitError =
    error === "Maximum Limit Exceeded for ERecipt Print" ? true : false;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    window.scroll({ top: 0, behavior: "auto" });
  }, []);

  return (
    <div>
      <div className={styles["header"]}>
        <span>{t("label_eReceipt")}</span>
      </div>
      <div className={styles["no-result-icon-wrapper"]}>
        <div className={styles["no-result-icon"]}>
          <TGIcon
            icon={`${isLimitError ? "cross-warn-icon" : "ereceipt-error-icon"}`}
            fillColor=""
            size=""
          />
        </div>
        <div className={styles["e-Receipt-no-result-msg"]}>
          {t(
            `${
              isLimitError ? "ereceipt_request_limit" : "label_eReceipt_error"
            }`
          )}

          <div className={styles["e-Receipt-no-result-desc"]}>
            {t(
              `${
                isLimitError
                  ? "ereceipt_request_limit_desc"
                  : "label_eReceipt_criteria"
              }`
            )}
          </div>
        </div>
        <div className={styles["button-container"]}>
          <TGButton
            label="Back to Home"
            variant="secondary"
            customClassName={styles["custom-class-secondary"]}
            onClick={() => {
              navigate("/");
            }}
          />
          <TGButton
            label={t(
              `${isLimitError ? "label_another_eReceipt" : "label_try_again"}`
            )}
            variant="primary"
            customClassName={styles["custom-class-primary"]}
            onClick={() => {
              dispatch(resetEreceiptDataRequest());
              navigate(`/${i18n.language}/e-Receipt`);
            }}
          />
        </div>
      </div>
    </div>
  );
};
