import React, { useEffect, useRef, useState } from "react";
import TGIcon from "../../../../../../shared/tg-icon";
import TGInput from "../../../../../../shared/tg-input";
import styles from "./booking-information.module.scss";
import TGCheckBox from "../../../../../../shared/tg-checkbox";
import TGButtonVariants from "../../../../../../shared/tg-button-variants";
import useScreenSize from "../../../../../../utils/hook/useScreenSize";
import { EReceiptBookingInfo } from "../../../../models/models";
import { useDispatch, useSelector } from "react-redux";
import termConditionData from "../../../../../../../public/json/termConditions.json";
import TGModal from "../../../../../../shared/tg-modal";
import selectedContent from "../../../../../../../public/json/en-th/ereceipt-howtoget.json";
import { useNavigate, useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../../slice/RootReducer";
import { fetchMyProfileRequest } from "../../../../slice/fetchMainMemberProfileSlice";
import { config } from "../../../../../../config/global";
import {
  getEReceiptDataRequest,
  resetEreceiptDataRequest,
  getEreceiptTermsSuccess,
  getEreceiptTermsFailure,
} from "../../../../slice/eReceiptSlice";
import { blockSpecialChar } from "../../../../../../utils/helper";
import isAuthenticate from "../../../../../../utils/hook/isAuthenticate";

const BookingInformation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const profile = useSelector(
    (state: RootState) => state?.osciReducer?.MyProfileDeails.myprofileData
  );
  const isAuthenticated = isAuthenticate(); //  To check whether user is singed in or not
  const { eReceiptDetails } = useSelector(
    (state: RootState) => state?.osciReducer?.myTrips
  );
  const prefilledInput =
    sessionStorage.getItem("isROPLoggedIn") === "true"
      ? name?.split(" ")[name?.split(" ").length - 1].toUpperCase()
      : name;
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  //useState
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [enrollmentData, setEnrollementData] = useState<EReceiptBookingInfo>({
    firstName: "",
    lastName: "",
    bookingRef: "",
    termsConditions: "",
  });
  const [errors, setErrors] = useState<Partial<EReceiptBookingInfo>>({});
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isNameError, setIsNameError] = useState(false);
  const [isBookingNoError, setIsBookingNoError] = useState(false);
  const [maxLength, setMaxLength] = useState(13);
  const [ticketNo, setTicketNo] = useState("");
  const [isLastNameError, setIsLastNameError] = useState(false);
  const [lastName, setLastName] = useState(
    isAuthenticated ? prefilledInput : ""
  );
  const [isTicketNoError, setIsTicketNoError] = useState(false);
  const bookingIDRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  // const location = useLocation();
  // const { pastTripEReceipt } = location.state || [];
  const handleKeyDown = (e: any, nextRef: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (nextRef?.current) {
        nextRef?.current?.focus();
      }
    }
  };
  useEffect(() => {
    dispatch(resetEreceiptDataRequest());
    // if(location.state === undefined){
    let element: HTMLElement | null = null;
    element = document.querySelector<HTMLInputElement>(
      `input[name="bookingRef"]`
    );
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    // }
  }, []);
  useEffect(() => {
    if (isAuthenticated && eReceiptDetails === undefined) {
      setLastName(prefilledInput);
      setIsLastNameError(true);
    } else if (isAuthenticated && eReceiptDetails !== undefined) {
      setLastName(eReceiptDetails?.lastName);
      setIsLastNameError(true);
    }
    if (/^(0|[1-9][0-9]*)$/.test(ticketNo)) {
      setMaxLength(13);
    } else if (/^[a-zA-z0-9]{0,20}/.test(ticketNo)) {
      setMaxLength(6);
    }
  }, [ticketNo]);

  //useEffect
  useEffect(() => {
    if (showModal) {
      document.documentElement.style.setProperty("--html-position", "fixed");
    } else {
      document.documentElement.style.setProperty("--html-position", "auto");
    }
  }, [showModal]);
  useEffect(() => {
    setEnrollementData({
      ...enrollmentData,
      bookingRef: ticketNo,
    });
  }, [ticketNo]);

  useEffect(() => {}, [enrollmentData.termsConditions]);
  useEffect(() => {
    dispatch(fetchMyProfileRequest());
  }, [dispatch]);
  const { isLoading, eReceiptBlob, error, termsData } = useSelector(
    (state: RootState) => state?.osciReducer?.eReceipt
  );

  useEffect(() => {
    if (profile) {
      setEnrollementData({
        ...enrollmentData,
        firstName:
          Object.keys(eReceiptDetails).length === 0
            ? profile?.firstName || ""
            : eReceiptDetails.firstName,
        lastName:
          Object.keys(eReceiptDetails).length === 0
            ? profile?.lastName || ""
            : eReceiptDetails.lastName,
        bookingRef:
          Object.keys(eReceiptDetails).length === 0
            ? ""
            : eReceiptDetails.ticketno,
      });
    }
  }, [profile]);

  const filteredValues = Object.fromEntries(
    Object.entries(errors).filter(
      ([key, value]) => value !== undefined && value !== ""
    )
  );

  //useRef
  const termConditionRef = useRef<any>(null);
  //handleFunctions
  const handleCloseModal = () => {
    setShowModal(false);
    setShowTerms(false);
  };

  function scrollToErrorFields(errorKeys: (keyof EReceiptBookingInfo)[]) {
    let currentIndex = 0;

    function scrollToNextError() {
      if (currentIndex >= errorKeys.length) return;

      const key = errorKeys[currentIndex];
      let element: HTMLElement | null = null;

      element = document.querySelector<HTMLInputElement>(
        `input[name="${key}"]`
      );

      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        currentIndex++;
      } else {
        currentIndex++;
        scrollToNextError(); // Move to the next field if the current one isn't found
      }
    }
    scrollToNextError();
  }
  useEffect(() => {
    if (isSuccess && eReceiptBlob) {
      navigate(`/${i18n.language}/e-Receipt/success`);
    } else if (isSuccess && error) {
      navigate(`/${i18n.language}/e-Receipt/error`);
    }
  }, [eReceiptBlob, isSuccess, error]);
  //Regex
  const bookingRegex = new RegExp(
    /^(?=.{6}$)(?![0-9]{6})(?![a-zA-Z]{6})[a-zA-Z0-9]{6}$/
  ); // regex exp for booking reference no
  const ticketRegex = new RegExp(/^[0-9]{13}$/); // regex exp for ticket no

  const handleBookingNum = (a: any, label: any) => {
    if (blockSpecialChar(a.target.value)) {
      setTicketNo(a.target.value.toUpperCase());
      setIsBookingNoError(false);
      if (a.target.value.length > 0 && a.target.value != null) {
        setIsTicketNoError(true);
        setTicketNo(a.target.value.toUpperCase());
      } else {
        setIsTicketNoError(false);
      }
    }
  };

  const handleSubmitEnroll = (event: React.FormEvent<HTMLButtonElement>) => {
    const newErrors: Partial<EReceiptBookingInfo> = {};
    Object.entries(enrollmentData).forEach(([key, value]) => {
      if (!value?.trim()) {
        newErrors[key as keyof EReceiptBookingInfo] = getFieldLabel(
          key as keyof EReceiptBookingInfo
        );
      } else if (
        (key === "firstName" && enrollmentData?.firstName?.length < 2) ||
        (key === "lastName" && enrollmentData?.lastName?.length < 2)
      ) {
        newErrors[key as keyof EReceiptBookingInfo] =
          key === "firstName"
            ? t("error_min_length_first_name")
            : t("error_min_length_last_name");
      } else if (key === "bookingRef") {
        if (
          enrollmentData?.bookingRef.length === 6 ||
          enrollmentData?.bookingRef.length === 13
        ) {
          if (
            bookingRegex.test(enrollmentData?.bookingRef) ||
            ticketRegex.test(enrollmentData?.bookingRef)
          ) {
            setErrors({
              ...errors,
              bookingRef: "",
            });
          } else {
            setIsBookingNoError(true);
            setErrors({
              ...errors,
              bookingRef: "error",
            });
            newErrors[key as keyof EReceiptBookingInfo] = t(
              "label_manage_example_error"
            );
          }
        } else {
          setIsBookingNoError(true);
          setErrors({
            ...errors,
            bookingRef: "error",
          });
          newErrors[key as keyof EReceiptBookingInfo] = t(
            "label_manage_example_error"
          );
        }
      }
    });
    setErrors(newErrors);
    if (errors.termsConditions == "error") {
      setErrors({ ...newErrors, termsConditions: "error" });
      newErrors["termsConditions"] = "error";
    }

    const keys = Object.keys(newErrors) as (keyof EReceiptBookingInfo)[];

    var filterUndefinedError: any[] = [];
    filterUndefinedError = keys.filter((key) => newErrors[key] !== undefined);

    // API call handled here
    let eReceiptUrl =
      process.env.REACT_APP_TG_API_BASE_URL + config.E_RECEIPT_API;
    let eReceiptParams = `pnrOrTicketNo=${enrollmentData.bookingRef}&firstName=${enrollmentData.firstName}&lastName=${enrollmentData.lastName}`;
    const printeReceiptnUrl = eReceiptUrl + "?" + eReceiptParams;

    if (filterUndefinedError.length === 0) {
      setIsSuccess(true);
      dispatch(
        getEReceiptDataRequest({
          url: printeReceiptnUrl,
          profileInfo: {
            firstName: enrollmentData.firstName,
            lastName: enrollmentData.lastName,
            bookingRef: enrollmentData.bookingRef,
          },
        })
      );
    } else {
      filterUndefinedError = keys.filter((key) => newErrors[key] !== undefined);
    }
    scrollToErrorFields(keys);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setEnrollementData({ ...enrollmentData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const checkedChange = (e: any): void => {
    setIsChecked(!isChecked);
    if (termConditionRef.current) {
      const value = termConditionRef.current.checked;
      setEnrollementData({
        ...enrollmentData,
        termsConditions: value.toString(),
      });
      if (value == true) {
        setErrors({ ...errors, termsConditions: "" });
      } else {
        setErrors({ ...errors, termsConditions: "error" });
      }
    }
  };

  const getFieldLabel = (fieldName: keyof EReceiptBookingInfo) => {
    const labels: { [key in keyof EReceiptBookingInfo]: string } = {
      firstName: t("signin_email_required"),
      lastName: t("signin_email_required"),
      bookingRef: t("label_manage_example_error"),
      termsConditions: t("signin_email_required"),
    };
    return labels[fieldName];
  };
  let condition =
    enrollmentData.bookingRef?.length &&
    enrollmentData.firstName !== "" &&
    enrollmentData.firstName !== undefined &&
    enrollmentData.lastName !== "" &&
    enrollmentData.lastName !== undefined;
  const TERMS_URL = config.TEAMSITE_ERECEIPT_TERMS;
  useEffect(() => {
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: TERMS_URL,
      isTeamsite: true,
      successAction: getEreceiptTermsSuccess,
      errorAction: getEreceiptTermsFailure,
    });
  }, [dispatch]);

  return (
    <>
      {/* {isDesktopView && ( */}
      <div className={styles.outterEnrollmentWrapper}>
        <div className={styles.outterEnrollWrapper}>
          <div className={styles.outterEnroll}>
            <div className={styles.outterEnroll}>
              <div className={styles.headingContainer}>
                <div className={styles.title}>
                  {t("label_booking_information")}
                </div>
                <div className={styles.description}>
                  {t("label_booking_information_description")}
                </div>
                <div className={styles.notice}>
                  <span
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    {t("label_eReceipt_notice")}
                  </span>
                  {showModal && (
                    <TGModal
                      show={showModal}
                      customClassNamePrimary="customizeModal"
                      handleClose={handleCloseModal}
                      buttonPresent={false}
                      customOffCanvasClass={styles["notice-bar-offcanvas"]}
                      bodyClassName={styles["booking-info-body"]}
                      heading={!isDesktopView ? t("label_eReceipt_notice") : ""}
                      customModalClass={styles["ereciptClass"]}
                    >
                      <div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: selectedContent?.description,
                          }}
                        ></div>
                      </div>
                    </TGModal>
                  )}
                </div>
              </div>
              <div className={styles.primaryEnrollContainer}>
                <div className={`${styles.primaryEnroll}`}>
                  <TGInput
                    isEnroll={true}
                    label={t("label_manage_booking_reference")}
                    value={enrollmentData.bookingRef || ""}
                    name="bookingRef"
                    onChange={(e: any, label: any) =>
                      handleBookingNum(e, label)
                    }
                    isError={isBookingNoError}
                    errorLabel={t("label_manage_example_error")}
                    customClassName={styles.fieldContainer}
                    validation="bookingRef"
                    maxLength={13}
                    ref={bookingIDRef}
                    onKeyDown={(e: any) => handleKeyDown(e, firstNameRef)}
                    focus={true}
                  />
                </div>
                <div
                  className={
                    isBookingNoError
                      ? `${styles["bookingRef-error"]}`
                      : `${styles["descriptionError"]}`
                  }
                >
                  <ul className={styles["error-points"]}>
                    <li>{t("label_manage_booking_example")}</li>
                    <li>{t("label_manage_ticket_example")}</li>
                  </ul>
                </div>
                <div className={styles.primaryEnroll}>
                  <TGInput
                    isEnroll={true}
                    label={t("label_reset_first_name")}
                    value={enrollmentData.firstName}
                    name="firstName"
                    onChange={handleChange}
                    isError={!!errors.firstName}
                    errorLabel={
                      !!errors.firstName &&
                      enrollmentData?.firstName?.length === 0
                        ? t("error_reset_first_name_required")
                        : errors.firstName
                    }
                    customClassName={styles.fieldContainer}
                    validation="name"
                    maxLength={40}
                    ref={firstNameRef}
                    onKeyDown={(e) => handleKeyDown(e, lastNameRef)}
                  />
                  <TGInput
                    isEnroll={true}
                    label={t("label_manage_last_name")}
                    value={enrollmentData.lastName}
                    name="lastName"
                    onChange={handleChange}
                    isError={!!errors.lastName}
                    errorLabel={
                      !!errors.lastName &&
                      enrollmentData?.lastName?.length === 0
                        ? t("error_reset_last_name_required")
                        : errors.lastName
                    }
                    customClassName={styles.fieldContainer}
                    validation="name"
                    maxLength={40}
                    ref={lastNameRef}
                    onKeyDown={(e) => handleKeyDown(e, null)}
                  />
                </div>
              </div>
              {/* Need to update  terms & condition url */}
              <div className={styles.checkSignup}>
                <div className={styles["terms-condition-check-box"]}>
                  <TGCheckBox
                    onChange={checkedChange}
                    checkref={termConditionRef}
                    checked={isChecked}
                    className="checkLabel"
                    customLabel="checkLabel"
                  />
                  <span>{t("profile_accept")}</span>
                  <span onClick={() => setShowTerms(true)}>
                    {t("profile_terms")}
                  </span>
                </div>
                {errors.termsConditions && (
                  <div className={styles.termsConditionsError}>
                    <TGIcon icon="error-icon" fillColor="" size="16" />
                    <div className={styles.termConditionText}>
                      {t("enroll_terms_condition")}
                    </div>
                  </div>
                )}
                {showTerms && (
                  <TGModal
                    show={showTerms}
                    customClassNamePrimary={styles["customizeModal"]}
                    handleClose={handleCloseModal}
                    buttonPresent={true}
                    customOffCanvasClass={styles["notice-bar-offcanvas"]}
                    bodyClassName={styles["booking-info-body"]}
                    heading={!isDesktopView ? t("profile_terms") : ""}
                    customModalClass={styles["ereciptClass"]}
                    buttonLabel="OK"
                    centered={true}
                  >
                    <div className={styles["terms-condition"]}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: termsData?.content,
                        }}
                      />
                    </div>
                  </TGModal>
                )}
              </div>
              <div className={styles.signupButton}>
                <TGButtonVariants
                  label={isLoading ? "" : t("button_reset_continue")}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="30px"
                  boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                  bgColor="#684B9B"
                  padding="16px"
                  textColor="#FFFFFF"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="160%"
                  borderDisabled="0.791px solid #E3D4FE"
                  bgColorDisabled="#E3D4FE"
                  boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                  bgColorHover="#684b9b"
                  borderHover="0.791px solid #E3D4FE"
                  boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                  height="50px"
                  width={isDesktopView ? "198px" : "100%"}
                  onClick={handleSubmitEnroll}
                  disabled={!condition}
                >
                  {isLoading ? (
                    <>
                      <Spinner
                        animation="grow"
                        variant="light"
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "5px",
                        }}
                      />
                      <Spinner
                        animation="grow"
                        variant="light"
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "5px",
                        }}
                      />
                      <Spinner
                        animation="grow"
                        variant="light"
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "5px",
                        }}
                      />
                    </>
                  ) : null}
                </TGButtonVariants>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingInformation;
